/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title

  const isBrowser = () => typeof window !== "undefined"

  const pathname = isBrowser() ? window.location.href.substring(0, window.location.href.length - 1) : '';
  const metaImage = image ? pathname + image : pathname + site.siteMetadata.image

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={metaTitle}
      titleTemplate={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:site`,
          content: `@wonderpackeco`
        },
        {
          name: `twitter:image`,
          content: metaImage
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
       {/*Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-174130211-1"/>
        <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments)}
            gtag('js', new Date());
            gtag('config', 'UA-174130211-1');
        `}
        </script>

      {/* Cookie Consent - Cookiebot - removed data-blockingmode="auto" */  }
      <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="a9bc9a5f-60a5-4fe0-b305-79de1066b7c4" type="text/javascript"/>

      {/* Klaviyo  */}
      <script type="text/javascript" async src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SFSBWE"/>

      {/* Ani */}
      <style type="text/css">{'.ani {will-change: transform, opacity;}'}</style>

    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default Seo
